<template>
  <div class="container">
    <div class="section">
      <div class="section-heading">
        <h3 class="title is-2">Profile</h3>
        <h4 class="subtitle is-5">Who?</h4>
      </div>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <figure class="image imghas-ratio28">
            <img src="../assets/profile.jpg" alt="">
          </figure>
        </div>
      </div>

      <section class="section">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="content is-medium">
              <h1 class="title">ひげだるま</h1>
              <p>関西在住で、めがね＆ひげの中年男性</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">

.section-heading {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

.image img {object-fit: cover; }
</style>